export default [
  { name: "Squall", birthday: "1981-08-23T00:00:00Z" },
  { name: "Quistis", birthday: "1980-10-04T00:00:00Z" },
  { name: "Zell", birthday: "1981-03-17T00:00:00Z" },
  { name: "Selphie", birthday: "1981-07-16T00:00:00Z" },
  { name: "Rinoa", birthday: "1981-03-03T00:00:00Z" },
  { name: "Irvine", birthday: "1981-11-24T00:00:00Z" },
  // { name: "Seifer", birthday: "1980-12-22T00:00:00Z" },
  // { name: "Edea"  /** birthday: "??" */ },
  // { name: "Laguna", birthday: "1954-01-03T00:00:00Z" },
  // { name: "Kiros", birthday: "1956-06-06T00:00:00Z" },
  // { name: "Ward", birthday: "1958-02-25T00:00:00Z" }
]