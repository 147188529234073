<template>
  <menu-box>
    <div class="misc-info">
      <div 
        class="misc-info__play-time"
        @mouseenter="setHelpText('Total play time')"
        @mouseleave="resetHelpText()">
        <span class="play-time__label">PLAY</span>
        <span class="play-time__hours numerical-spacing">{{ playTime.hours }}<span class="hours__separator">:</span>{{ playTime.minutes.toString().padStart(2, 0) }}</span>
      </div>
      <div 
        class="misc-info__rank"
        @mouseenter="setHelpText('Years of experience')"
        @mouseleave="resetHelpText()">
        <span class="rank__label">RANK</span>
        <span class="rank__number numerical-spacing">{{ Math.floor(playTime.hours / 8760) }}</span>
      </div>
      <div 
        class="misc-info__contributions"
        @mouseenter="setHelpText('Total contributions')"
        @mouseleave="resetHelpText()">
        <a class="contributions__number numerical-spacing" href="https://github.com/DefrostedTuna" target="_blank">{{ contributions }}<span class="contributions__symbol">c</span></a>
      </div>
    </div>
  </menu-box>
</template>

<script>
import MenuBox from '@/components/partials/MenuBox'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "MiscInfo",

  components: {
    MenuBox
  },

  data() {
    return {
      playTime: {
        hours: 0,
        minutes: 0
      }
    }
  },

  computed: {
    ...mapGetters({
      contributions: 'contributions/getContributions'
    })
  },

  methods: {
    /**
     * The actions mapped from Vuex.
     */
    ...mapActions({
      setContributions: 'contributions/setContributions',
      setHelpText: 'helpText/setHelpText'
    }),

    /**
     * Resets the help text back to an empty string.
     * 
     * @returns {Void}
     */
    resetHelpText() {
      this.setHelpText('')
    },

    /**
     * Calculates the play time. Also sets the play time on the Vue component.
     * 
     * Play time is based on the year that I started programming (2013)
     * 
     * @returns {Object}
     */
    calculatePlayTime() {
      let start = new Date(2013, 0, 1, 0, 0, 0)
      let end = new Date()
      let diff = Math.abs(end - start)
      // Convert the milliseconds into seconds, and then the seconds into minutes.
      // From there, calculate the remainder that's left over after dividing by 60.
      let minutes = Math.floor(((diff / 1000) / 60) % 60)
      let hours = Math.floor(diff / 36e5)

      return this.playTime = {
        hours,
        minutes
      }
    },

    /**
     * Fetches the contributions made over the lifetime of my Github account.
     * 
     * @returns {Void}
     */
    fetchContributions() {
      let start = new Date(Date.UTC(2013, 0, 1, 0, 0, 0)) // Start of 2013
      let end = new Date(Date.UTC(2013, 11, 31, 23, 59, 0)) // End of 2013
      let now = new Date()
      let numberOfYears = Math.floor(Math.abs(now - start) / 3.154e+10)

      for (let i = 0; i <= numberOfYears; i++) {
        this.fetchContribution(start.toISOString(), end.toISOString())
        start.setFullYear(start.getFullYear() + 1)
        end.setFullYear(end.getFullYear() + 1)
      }
    },

    /**
     * Fetches the total contributions for the given date range.
     * 
     * Note that this is restricted to one calendar year at a time.
     * 
     * @returns {Void}
     */
    fetchContribution(start, end) {
      // I'm not really worried about hard coding this. 
      // It's a throwaway key specifically for this purpose.
      let headers = {
        'Authorization': `bearer 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`
      }
      let body = {
        "query": `query {
          user(login: "DefrostedTuna") {
            contributionsCollection(from: "${start}", to: "${end}") {
              restrictedContributionsCount
              contributionCalendar {
                totalContributions
              }
            }
          }
        }`
      }

      fetch('https://api.github.com/graphql', { method: 'POST', body: JSON.stringify(body), headers: headers })
        .then(response => {
          response.json().then(data => {
            this.setContributions(this.contributions + data.data.user.contributionsCollection.contributionCalendar.totalContributions)
          })
        })
        .catch(error => {
          console.error(error)
        })
    }
  },

  created() {
    if (! this.contributions) {
      this.fetchContributions()
    }
    this.calculatePlayTime()

    // Update the timer once every second.
    setInterval(() => {
      this.calculatePlayTime()
    }, 1000)
  }
}
</script>

<style lang="scss" scoped>
.misc-info {
  font-weight: bold;

  div {
    margin-bottom: .25rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.misc-info__play-time,
.misc-info__rank,
.misc-info__contributions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.play-time__label {
  margin-right: 2rem;
}

.misc-info__contributions {
  justify-content: flex-end; 
}

.contributions__number {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

.contributions__symbol {
  font-family: Lato;
  font-weight: 400;
}

.hours__separator {
  animation: blinker 2s step-start infinite;
}

@keyframes blinker {  
  50% { opacity: 0; }
}
</style>