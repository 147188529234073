<template>
  <div 
    class="scrolling-text-container" 
    ref="container" 
    @mouseenter="isHovering = true" 
    @mouseleave="isHovering = false">
    <span 
      class="scrolling-text" 
      :class="{
        'scrolling-text--paused': isHovering && playAnimation
      }"
      ref="text" 
      :style="cssAnimationStyles"
      @animationend="resetAnimation()"
    >
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: "ScrollingText",

  data() {
    return {
      isHovering: false,
      playAnimation: true,
      containerWidth: 0,
      textWidth: 0,
      resizeObserver: null
    }
  },

  computed: {
    /**
     * The difference in width between the container and the text.
     * 
     * This will be represented in pixels, and will be positive or
     * negative based on whether the text is overflowing or not.
     * 
     * @returns {Number}
     */
    overflowWidth() {
      return this.textWidth - this.containerWidth
    },

    /**
     * The animation styles to apply to the scrolling text element.
     * 
     * The timing for these styles must be calculated based on the
     * width of the element. Given that the element's width will
     * change based on the text displayed, we need to calculate
     * this on the fly in order to get the duration of the animation.
     * This will allow the scroll rate to maintain a steady rate.
     * 
     * @returns {Object}
     */
    cssAnimationStyles() {
      let styles = {
        'animation': `scrolling-text ${this.animationTiming}s linear 10s`
      }

      return (this.playAnimation && this.overflowWidth > 0) ? styles : {}
    },

    /**
     * The number of seconds required to maintain a steady scroll rate.
     * 
     * @returns {Number} 
     */
    animationTiming() {
      if (this.overflowWidth <= 0) {
        return 0
      }

      let pixelsPerSecond = 100
      let totalWidth = this.containerWidth + this.overflowWidth

      return Math.floor(totalWidth / pixelsPerSecond)
    }
  },

  methods: {
    /**
     * Resets the animation state so that pauses between animations will be consistent.
     * 
     * This is needed to circumvent CSS animation limitations. Normally, keyframes would
     * be used to delay an animation, setting empty percentages in order to "pause" the
     * animation. This would be too variable for the implementation as we need a steady
     * pause between animations. By 'blipping' the animation styling, we're able to
     * essentially "reset" the animation by reapplying the style. Doing so will cause
     * the start delay of th animation to kick back in and start from the beginning.
     * 
     * @returns {Void}
     */
    resetAnimation() {
      this.playAnimation = false

      this.$nextTick(() => {
        if (this.overflowWidth > 0) {
          this.playAnimation = true
        }
      })
    },

    /**
     * Sets the element widths for both the container and the scrolling text.
     * 
     * @returns {Void}
     */
    setElementWidths() {
      if (! this.$refs.container || ! this.$refs.text) {
        return
      }
      
      let containerWidth = this.$refs.container.innerWidth || this.$refs.container.clientWidth
      let textWidth = this.$refs.text.innerWidth || this.$refs.text.clientWidth

      this.containerWidth = containerWidth
      this.textWidth = textWidth
    }
  },

  mounted() {
    setTimeout(() => {
      this.setElementWidths()
    })
    this.resizeObserver = new ResizeObserver(this.setElementWidths)
    this.resizeObserver.observe(this.$refs.container)
  },

  destroyed() {
    if (this.resizeObserver && this.$refs.container) {
      this.resizeObserver.unobserve(this.$refs.container)
    }
  }
}
</script>

<style lang="scss">
.scrolling-text-container {
  overflow-x: hidden;
}

.scrolling-text {
  white-space: nowrap;
  display: inline-block;
}

.scrolling-text--paused {
  animation-play-state: paused !important;
}

@keyframes scrolling-text {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(calc(-100% - 2rem));
  }
}
</style>