export default {
  /**
   * Gets the value of isAnimating on the store.
   * 
   * @param {Object} state
   * 
   * @returns {Boolean}
   */
  isAnimating(state) {
    return state.isAnimating
  },

  /**
   * Gets the value of isOpen on the store.
   * 
   * @param {Object} state
   * 
   * @returns {Boolean}
   */
  isOpen(state) {
    return state.isOpen
  },

  /**
   * Gets the value of hideOnDesktop on the store.
   * 
   * @param {Object} state
   * 
   * @returns {Boolean}
   */
  getHideOnDesktop(state) {
    return state.hideOnDesktop
  },

  /**
   * Gets the state of the menu and whether or not it is being shown on the screen.
   * 
   * The menu should be shown if: 
   * ((the viewport is within mobile view) OR (the menu is hidden on desktop)) 
   * AND (the menu's state is open).
   * 
   * @param {Object} state
   * @param {Object} getters
   * 
   * @returns {Boolean}
   */
  isBeingShown(state, getters) {
    //  (the desktop is in mobile view |OR| the menu is hidden on desktop) |AND| the menu is open
    if ((! getters.isDesktopViewport || getters.hideOnDesktop) && getters.isOpen) {
      return true
    }

    return false
  }
}