export default {
  /**
   * Sets the value of isAnimating on the store.
   * 
   * @param {Object}  context
   * @param {Boolean} payload
   * 
   * @returns {Void}
   */
  setIsAnimating({commit}, payload) {
    commit('setIsAnimating', payload)
  },

  /**
   * Sets the value of isOpen on the store.
   * 
   * @param {Object}  context
   * @param {Boolean} payload
   * 
   * @returns {Void}
   */
  setIsOpen({commit}, payload) {
    commit('setIsOpen', payload)
  },

  /**
   * Sets the value of desktopBreakpoint on the store.
   * 
   * @param {Object} context
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setDesktopBreakpoint({commit}, payload) {
    commit('setDesktopBreakpoint', payload)
  },

  /**
   * Sets the value of hideOnDesktop on the store.
   * 
   * @param {Object}  context
   * @param {Boolean} payload
   * 
   * @returns {Void}
   */
  setHideOnDesktop({commit}, payload) {
    commit('setHideOnDesktop', payload)
  },

  /**
   * Sets the value of isDesktopViewport on the store.
   * 
   * @param {Object}  context
   * @param {Boolean} payload
   * 
   * @returns {Void}
   */
  setIsDesktopViewport({commit}, payload) {
    commit('setIsDesktopViewport', payload)
  },

  /**
   * Sets the state of the menu to open.
   * 
   * @param {Object} context
   * 
   * @returns {Void}
   */
  openMenu({dispatch}) {
    dispatch('setIsAnimating', true)
    dispatch('setIsOpen', true)
  },

  /**
   * Sets the state of the menu to closed.
   * 
   * @param {Object} context
   * 
   * @returns {Void}
   */
  closeMenu({dispatch}) {
    dispatch('setIsAnimating', true)
    dispatch('setIsOpen', false)
  }
}