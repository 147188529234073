export default {
  /**
   * Sets the value of viewports on the store.
   * 
   * @param {Object} state
   * @param {Array} payload
   * 
   * @returns {Void}
   */
  setViewports(state, payload) {
    state.viewports = payload
  },

  /**
   * Sets the value of activeViewport on the store.
   * 
   * @param {Object} state
   * @param {Array} payload
   * 
   * @returns {Void}
   */
  setActiveViewports(state, payload) {
    state.activeViewports = payload
  }
}