import Vue from 'vue'
import Vuex from 'vuex'
import contributions from './modules/contributions'
import helpText from './modules/help-text'
import menu from './modules/menu'
import viewport from './modules/viewport'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    contributions,
    helpText,
    menu,
    viewport
  }
})