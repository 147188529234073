<template>
<div class="menu-box">
  <div class="menu-box__inner" :class="{ 'menu-box__inner--has-title': title }">
    <span v-if="title" class="menu-box__title">{{ title }}</span>
    <div class="menu-box__content">
      <slot></slot>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "MenuBox",
  
  props: {
    /**
     * The title to display in the top left of the menu box.
     */
    title: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-box {
  border: .25rem solid #282a27;
  background: #282a27;
}

.menu-box__inner {
  position: relative;
  padding: .5rem 1rem;
  min-width: 6.25rem;
  height: 100%;
  width: 100%;
  text-align: left;
  
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
  text-shadow: .0625rem .0625rem .1875rem rgba(0, 0, 0, 0.6);

  background: #585858;
  background: linear-gradient(90deg, rgba(60, 60, 60, 0.95) 5%, rgba(69, 69, 69, 0.95) 15%, rgba(79, 79, 79, 0.95) 35%, rgba(86, 86, 86, 0.95) 65%, rgba(96, 96, 96, 0.95) 100%), url('~@/assets/img/brushed-texture.jpg');
  border-top: .25rem solid #6a6c69;
  border-left: .25rem solid #6a6c69;
  border-right: .25rem solid #323232;
  border-bottom: .25rem solid #323232;
}

.menu-box__inner--has-title {
  padding-top: .75rem;
}

.menu-box__title {
  position: absolute;
  top: -.375rem;
  left: .5rem;
  color: rgba(255, 255, 255, 0.8);
  font-family: Lato, sans-serif;
  font-weight: 900;
  font-size: 1rem;
  text-transform: uppercase;
  text-shadow: 
    #000 -.0625rem -.0625rem .25rem,
    #000 .0625rem -.0625rem .25rem,
    #000 -.0625rem .0625rem .25rem,
    #000 .0625rem .0625rem .25rem;
}

.menu-box__content {
  height: 100%;
  width: 100%;
  overflow: auto;
}
</style>