export default {
  /**
   * Sets the value of contributions on the store.
   * 
   * @param {Object} context
   * @param {Number} payload
   * 
   * @returns {Void}
   */
  setContributions({commit}, payload) {
    commit('setContributions', payload)
  }
}