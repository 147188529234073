<template>
  <menu-box>
    <div class="main-menu">
      <div class="menu-items">
        <template v-for="(item, index) in menuItems">
          <router-link 
            v-if="item.routeName" 
            :key="index"
            :to="{ name: item.routeName }"
            class="menu-item"
            :class="{
              'menu-item--disabled': item.disabled
            }"
            @mouseenter.native="setHelpText(item.helpText)"
            @mouseleave.native="resetHelpText()">
            {{ item.text }}
          </router-link>

          <a 
            v-if="item.link"
            :key="index"
            class="menu-item"
            :class="{
              'menu-item--disabled': item.disabled
            }"
            :href="item.link"
            target="_blank"
            @mouseenter="setHelpText(item.helpText)"
            @mouseleave="resetHelpText()">
            {{ item.text }}
          </a>

          <span
            v-if="! item.routeName && ! item.link"
            :key="index"
            class="menu-item"
            :class="{
              'menu-item--disabled': item.disabled
            }"
            @mouseenter="setHelpText(item.helpText)"
            @mouseleave="resetHelpText()">
            {{ item.text }}
          </span>
        </template>
        <span 
          class="menu-item" 
          :class="{
            'hide': activeViewports.some(({name}) => name === 'desktop')
          }"
          @click="closeMenu()"
          @mouseenter="setHelpText('Close Main Menu')"
          @mouseleave="resetHelpText()">
          Exit
          </span>
      </div>
    </div>
  </menu-box>
</template>

<script>
import MenuBox from '@/components/partials/MenuBox'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "MainMenu",

  components: {
    MenuBox
  },

  data() {
    return {
      menuItems: [
        {
          text: 'Party',
          helpText: 'Party Menu',
          routeName: 'party',
          disabled: false
        },
        {
          text: 'Junction',
          helpText: 'Junction Menu',
          routeName: null,
          disabled: true
        },
        {
          text: 'Item',
          helpText: 'Item Menu',
          routeName: null,
          disabled: true
        },
        {
          text: 'Magic',
          helpText: 'Magic Menu',
          routeName: null,
          disabled: true
        },
        {
          text: 'Status',
          helpText: 'Status Menu',
          routeName: 'status',
          disabled: false
        },
        {
          text: 'GF',
          helpText: 'Guardian Force Menu',
          routeName: null,
          disabled: true
        },
        {
          text: 'Ability',
          helpText: 'Use GF ability',
          routeName: null,
          disabled: true
        },
        {
          text: 'Switch',
          helpText: 'Select party members',
          routeName: null,
          disabled: true
        },
        {
          text: 'Card',
          helpText: 'Look at cards',
          routeName: null,
          disabled: true
        },
        {
          text: 'Config',
          helpText: 'Configuration Menu',
          routeName: null,
          disabled: true
        },
        {
          text: 'Tutorial',
          helpText: 'Explanation',
          routeName: null,
          disabled: true
        },
        {
          text: 'Github',
          helpText: 'Github Profile',
          routeName: null,
          link: 'https://github.com/DefrostedTuna',
          disabled: false
        },
        {
          text: 'Resume',
          helpText: 'Resume Link',
          routeName: null,
          link: 'https://drive.google.com/file/d/1NUK-sUO76uppwJbyT4Jok86c_hzBUN0u/view',
          disabled: false
        },
        {
          text: 'Save',
          helpText: 'Save Menu',
          routeName: null,
          disabled: true
        }
      ]
    }
  },

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      helpText: 'helpText/getHelpText',
      activeViewports: 'viewport/getActiveViewports'
    })
  },

  methods: {
    /**
     * The actions mapped from Vuex.
     */
    ...mapActions({
      setHelpText: 'helpText/setHelpText',
      closeMenu: 'menu/closeMenu'
    }),

    /**
     * Resets the help text back to an empty string.
     * 
     * @returns {Void}
     */
    resetHelpText() {
      this.setHelpText('')
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-item {
  display: block;
  padding: .1rem 0;
  text-decoration: none;
  color: inherit;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &:hover {
    cursor: pointer;
  }
}

.menu-item--disabled {
  color: rgba(255, 255, 255, 0.37);

  &:hover {
    cursor: not-allowed;
  }
}
</style>