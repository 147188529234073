export default {
  /**
   * Whether or not the navigation menu is currently in the process of being opened or closed.
   * 
   * @param {Boolean}
   */
  isAnimating: false,

  /**
   * Whether the state of the menu is open or closed.
   * 
   * @param {Boolean}
   */
  isOpen: false,

  /**
   * The breakpoint at which the application switches between desktop and mobile.
   * 
   * Note: This should match the breakpoint within the CSS vars file: src/assets/css/global-variables.scss.
   * 
   * @param {String}
   */
  desktopBreakpoint: '50rem',

  /**
   * Whether or not the menu should be hidden when the application is being displayed on a large screen.
   * 
   * @param {Boolean}
   */
  hideOnDesktop: true,

  /**
   * Whether or not the application has passed the desktop breakpoint defined.
   * 
   * @param {Boolean}
   */
  isDesktopViewport: false
}