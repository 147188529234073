export default {
  /**
   * Gets the value of helpText on the store.
   * 
   * @param {Object} state
   * 
   * @returns {String}
   */
  getHelpText(state) {
    return state.helpText
  }
}