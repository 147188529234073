<template>
  <div
    id="nav-container" 
    class="nav-container" 
    :class="{ 
      'nav-container--open': menu.isOpen, 
      'nav-container--desktop': activeViewports.some(({name}) => name === 'desktop') && ! hideOnDesktop,
      'nav-container--animating': menu.isAnimating
    }"
    @transitionend="setIsAnimating(false)">
    <div 
      class="nav"
      :class="{
        'nav--desktop': activeViewports.some(({name}) => name === 'desktop') && ! hideOnDesktop
      }">
      <main-menu></main-menu>
    </div>
    <div class="misc-info-container">
      <misc-info></misc-info>
    </div>
  </div>
</template>

<script>
import MainMenu from '@/components/partials/MainMenu'
import MiscInfo from '@/components/partials/MiscInfo'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "NavContainer",

  components: {
    MainMenu,
    MiscInfo
  },

  props: {
    hideOnDesktop: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      activeViewports: 'viewport/getActiveViewports'
    }),

    /**
     * The menu object extracted from Vuex.
     * 
     * Vuex's `mapGetters` doesn't support being nested into an object, 
     * so the next best way to handle this seems to be to map them individually
     * inside of an object. This will allow the properties to be within object context.
     * 
     * @returns {Object}
     */
    menu() {
      return {
        isAnimating: this.$store.getters['menu/isAnimating'],
        isOpen: this.$store.getters['menu/isOpen'],
      }
    }
  },

  methods: {
    /**
     * The actions mapped from Vuex.
     */
    ...mapActions({
      openMenu: 'menu/openMenu',
      closeMenu: 'menu/closeMenu',
      setIsAnimating: 'menu/setIsAnimating',
      setHideOnDesktop: 'menu/setHideOnDesktop'
    })
  },

  mounted() {
    this.setHideOnDesktop(this.hideOnDesktop)
  }
}
</script>

<style lang="scss" scoped>
.nav-container {
  z-index: 3;
  position: absolute;
  top: .5rem;
  right: .5rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-height: calc(100vh - 1rem); // Top & bottom spacing bcuz absolute positioning. Wut?
  overflow-y: scroll;
  visibility: hidden;
  opacity: 0;
  transform: translateX(calc(100% + .5rem));
}

.nav-container--desktop {
  position: relative;
  top: initial;
  right: initial;
  bottom: initial;
  max-height: initial;
  transform: initial;
  margin-left: .5rem;
  visibility: visible;
  opacity: 1;

  // Hide scrollbars on desktop.
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar { /* Chrome */
    display: none;
  }
}

.nav-container--open {
  transform: translateX(0%);
  visibility: visible;
  opacity: 1;
  // max-height: calc(100vh - .5rem);
}

.nav-container--animating {
  transition: transform .5s ease, visibility .5s ease, opacity .5s ease;
}

.nav--desktop {
  margin-bottom: auto;
}
</style>