export default {
  /**
   * Sets the value of helpText on the store.
   * 
   * @param {Object} state
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setHelpText(state, payload) {
    state.helpText = payload
  }
}