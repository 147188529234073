var render = function render(){var _vm=this,_c=_vm._self._c;return _c('home-layout',[_c('div',{staticClass:"party-panel",class:{
      'party-panel--desktop': _vm.activeViewports.some(({name}) => name === 'desktop')
    }},[_c('menu-box',{staticClass:"party-panel__menu-box",attrs:{"title":"Status"}},[_c('span',[_vm._v(" ")])]),_c('menu-box',{staticClass:"party-panel__menu-box party-panel__menu-box--main",attrs:{"title":"Status"},nativeOn:{"mouseenter":function($event){return _vm.setHelpText('Status Menu')},"mouseleave":function($event){return _vm.resetHelpText()},"click":function($event){return _vm.goToStatus()}}},[_c('character-card',{attrs:{"character":_vm.character}})],1),_c('menu-box',{staticClass:"party-panel__menu-box",attrs:{"title":"Status"}},[_c('span',[_vm._v(" ")])]),_c('menu-box',{staticClass:"party-panel__menu-box"},[_c('div',{staticClass:"party-panel__reserve-members",class:{
          'party-panel__reserve-members--desktop': _vm.activeViewports.some(({name}) => name === 'reserve-members')
        }},_vm._l((_vm.reserve),function(member,index){return _c('div',{key:index,staticClass:"reserve-member",class:{
            'reserve-member--desktop': _vm.activeViewports.some(({name}) => name === 'reserve-members')
          }},[_c('character-card',{attrs:{"character":member,"reserve":true}})],1)}),0)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }