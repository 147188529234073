<template>
  <div class="progress-bar" :class="'progress-bar--' + color">
    <span class=progress-bar__fill>
      <span class="fill__color" :class="'fill__color--' + color" :style="'width: ' + width + '%;'"></span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * The current width of the progress bar as a percent, represented as a whole number.
     */
    width: {
      type: Number,
      required: false
    },

    /**
     * The color of the progress bar.
     * 
     * Valid options are: null|red|blue|white
     */
    color: {
      type: String,
      required: false
    }
  },
}
</script>

<style scoped lang="scss" type="text/scss">
.progress-bar {
  display: flex;
  flex-direction: column;
}

.progress-bar__fill {
  position: relative;
  width: 100%;
  height: .15em;
  background-color: #3b3b3b;
  border: .0625em solid rgba(0, 0, 0, 0.1);
}

.fill__color {
  position: absolute;
  top: -.0625em;
  bottom: -.0625em;
  left: -.0625em;
  z-index: 1;
  width: 100%;
  background-color: white;
  transition: width 0.25s ease;
  border-radius: 0 .0625em .0625em 0;
}

.fill__color--red {
  background-color: #8c3132;
}

.fill__color--blue {
  background-color: #315d8c;
}

.fill__color--white {
  background-color: #B3B3B3;
}
</style>