<template>
  <div class="character" :class="{ 'character--reserve': reserve, 'character--critical': isCritical }">
    <div class="character__name-container">
      <span v-if="'name' in character" class="character__name">{{ character.name }}</span>
    </div>
    <div class="character__stats-container">
      <span class="character__level">
        <span class="level__label">Lv</span>
        <span class="numerical-spacing">{{ characterLevel }}</span>
      </span>
      <div class="character__hp-container">
        <span class="character-hp">
          <span class="hp__label">HP</span>
          <span class="numerical-spacing">
            <span class="hp__current">{{ currentHP }}</span><span v-if="! reserve" class="hp__max">&nbsp;/ {{ maxHP }}</span> 
          </span>
        </span>
        <progress-bar class="hp__bar" :width="(currentHP / maxHP) * 100" color="red"></progress-bar>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from '@/components/partials/ProgressBar'
export default {
  name: "CharacterCard",

  components: {
    ProgressBar
  },

  props: {
    /**
     * The character object to be rendered.
     */
    character: {
      type: Object,
      required: false
    },

    /**
     * Whether or not the character is a reserve member.
     */
    reserve: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    /**
     * Determines if the character is in "critical" HP (25% or below).
     * 
     * @returns {Boolean}
     */
    isCritical() {
      return ((this.currentHP / this.maxHP) * 100) <= 25
    },

    /**
     * Randomly generates the character's current HP based on their configured maximum.
     * 
     * @returns {Number}
     */
    currentHP() {
      return Math.floor(Math.random() * this.maxHP);
    },

    /**
     * The character's maximum HP. 
     * This will be either their currently configured value, or a random value if one is not specified.
     * 
     * @returns {Number}
     */
    maxHP() {
      return 'hpMax' in this.character ? this.character.hpMax : Math.floor((Math.random() * 9099) + 100);
    },

    /**
     * The character's level, based on the configured birthday.
     * In the event no birthday is specified, `???` will be displayed instead.
     * 
     * @returns {String}
     */
    characterLevel() {
      if (! this.character.birthday) {
        return '??'
      }

      let birthday = new Date(this.character.birthday)
      let now = new Date()
      let years = Math.floor(Math.abs(now - birthday) / 3.154e+10)

      return years
    }
  }
}
</script>

<style lang="scss" scoped>
.character {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.character--reserve {
  flex-direction: column;
}

.character--critical {
  color: #f2ff00;
}

.character__name {
  display: block;
}

.character__class {
  display: block;
  font-size: .8em;
}

.character__stats-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.character__level {
  margin-left: 1rem;
  font-weight: bold;
}

.level__label {
  margin-right: 1rem;
}

.character__hp-container {
  margin-left: 1rem;
  font-weight: 900;
}

.character-hp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hp__label {
  margin-right: 1rem;
}

.hp__current,
.hp__max {
  display: inline-block;
  min-width: 5rem;
  text-align: right;
}

</style>