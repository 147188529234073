<template>
  <home-layout>
    <div 
      class="party-panel"
      :class="{
        'party-panel--desktop': activeViewports.some(({name}) => name === 'desktop')
      }">
      <!-- Blank Slot -->
      <menu-box 
        class="party-panel__menu-box"
        title="Status">
          <span>&nbsp;</span>
      </menu-box>

      <!-- Main Character -->
      <menu-box 
        class="party-panel__menu-box party-panel__menu-box--main"
        title="Status"
        @mouseenter.native="setHelpText('Status Menu')"
        @mouseleave.native="resetHelpText()"
        @click.native="goToStatus()">
          <character-card :character="character"></character-card>
      </menu-box>

      <!-- Blank Slot -->
      <menu-box 
        class="party-panel__menu-box"
        title="Status">
          <span>&nbsp;</span>
      </menu-box>

      <menu-box class="party-panel__menu-box">
        <div 
          class="party-panel__reserve-members"
          :class="{
            'party-panel__reserve-members--desktop': activeViewports.some(({name}) => name === 'reserve-members')
          }">
          <div 
            v-for="(member, index) in reserve"
            :key="index"
            class="reserve-member"
            :class="{
              'reserve-member--desktop': activeViewports.some(({name}) => name === 'reserve-members')
            }">
            <character-card :character="member" :reserve="true"></character-card>
          </div>
        </div>
      </menu-box>
    </div>
  </home-layout>
</template>

<script>
import HomeLayout from '@/views/layouts/HomeLayout'
import MenuBox from  '@/components/partials/MenuBox'
import CharacterCard from '@/components/partials/CharacterCard'
import character from '@/data/character'
import reserve from '@/data/reserve'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "PartyPanel",
  
  components: {
    HomeLayout,
    MenuBox,
    CharacterCard
  },

  data() {
    return {
      character: character,
      reserve: reserve
    }
  },

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      activeViewports: 'viewport/getActiveViewports'
    })
  },

  methods: {
    /**
     * The actions mapped from Vuex.
     */
    ...mapActions({
      setHelpText: 'helpText/setHelpText'
    }),

    /**
     * Resets the help text back to an empty string.
     * 
     * @returns {Void}
     */
    resetHelpText() {
      this.setHelpText('')
    },

    /**
     * Redirects the user to the status page.
     * 
     * @returns {Void}
     */
    goToStatus() {
      this.$router.push({ name: 'status' });
    }
  }
}
</script>

<style lang="scss" type="text/scss">
.party-panel {
  padding: .5rem;
}

// This will push the panel in from the left for a more "Authentic" feel for FFVIII.
.party-panel--desktop {
  padding-left: 1.5rem;
}

.party-panel__menu-box {
  margin-bottom: .0625rem;
}

.party-panel__menu-box--main:hover {
  cursor: pointer;
}

.party-panel__reserve-members {
  min-height: 22rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
}

.party-panel__reserve-members--desktop {
  flex-direction: row;
}

.reserve-member {
  margin-bottom: 1rem;
  padding-right: 1rem;
  width: 100%;
  min-width: 16rem;
  flex-grow: 1;
}

.reserve-member--desktop {
  width: 50%;
}
</style>