<template>
  <div class="home-layout">
    <div class="app-container">
      <div class="header-content-container">
        <div 
          class="header"
          :class="{
            'header--row': activeViewports.some(({name}) => name === 'mobile')
          }">
          <div class="header__item header__item--help">
            <menu-box title="Help">
              <transition name="fade" mode="out-in">
                <scrolling-text v-if="helpText" key="help-text">
                  {{ helpText }}
                </scrolling-text>
                <scrolling-text v-else key="default-text"></scrolling-text>
              </transition>
            </menu-box>
          </div>
          <div
            class="header__item header__item--menu"
            :class="{
              'hide': activeViewports.some(({name}) => name === 'desktop'),
              'super-small-screen-menu-fix': ! activeViewports.some(({name}) => name === 'mobile')
            }"
            @click="openMenu()">
            <menu-box title="Menu">
              <span class="item__text">Party</span>
            </menu-box>
          </div>
        </div>

        <div class="content">
          <slot></slot>
        </div>
      </div>

      <page-footer></page-footer>
    </div>

    <nav-container :hide-on-desktop="false"></nav-container>
  </div>
</template>

<script>
import PageFooter from '@/components/partials/PageFooter'
import MenuBox from '@/components/partials/MenuBox'
import NavContainer from '@/components/partials/NavContainer'
import ScrollingText from '@/components/partials/ScrollingText'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "HomeLayout",
  
  components: {
    PageFooter,
    MenuBox,
    NavContainer,
    ScrollingText
  },

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      helpText: 'helpText/getHelpText',
      activeViewports: 'viewport/getActiveViewports'
    })
  },

  methods: {
    /**
     * The actions mapped from Vuex.
     */
    ...mapActions({
      openMenu: 'menu/openMenu'
    })
  }
}
</script>

<style lang="scss" scoped>
.home-layout {
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: global-variables.$max-application-width;
  margin: auto;
  padding: .5rem;
}

.app-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 1px; // This is needed to prevent the text from expanding past the width limit, or wrapping.
}

.header {
  display: flex;
  flex-direction: column;
}

.header--row {
  flex-direction: row;
}

.header__item--help {
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
}

.header__item--menu {
  min-width: 10rem;
  flex-grow: 1;
  margin-left: .5rem;
  cursor: pointer;
}

.super-small-screen-menu-fix {
  margin-left: 0;
  margin-bottom: .5rem;
  order: -1;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .15s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>