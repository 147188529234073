<template>
  <div class="status-layout">
    <div class="app-container">
      <div class="header-content-container">
        <!-- Top row -->
        <div class="header header--row header--top">
          <div 
            class="header__empty-box"
            :class="{
              'hide': ! activeViewports.some(({name}) => name === 'mobile')
            }">
            <menu-box class="empty-box"></menu-box>
          </div>
          <div
            class="header__item header__item--menu"
            :class="{
              'super-small-screen-menu-fix': ! activeViewports.some(({name}) => name === 'desktop')
            }"
            @click="openMenu()">
            <menu-box title="Menu">
              <span class="item__text">Status</span>
            </menu-box>
          </div>
        </div>
        <!-- Bottom row -->
        <div 
          class="header header--row header--bottom"
          :class="{ 'super-small-screen-header-fix': ! activeViewports.some(({name}) => name === 'mobile') }">
          <menu-box 
            title="Help"
            class="header__item header__item--help">
            <transition name="fade" mode="out-in">
              <scrolling-text v-if="helpText" key="help-text">
                {{ helpText }}
              </scrolling-text>
              <scrolling-text v-else key="default-text">
                
              </scrolling-text>
            </transition>
          </menu-box>
        </div>
        <div class="content">
          <slot></slot>
        </div>
      </div>

      <page-footer></page-footer>
    </div>

    <nav-container></nav-container>
  </div>
</template>

<script>
import PageFooter from '@/components/partials/PageFooter'
import MenuBox from '@/components/partials/MenuBox'
import NavContainer from '@/components/partials/NavContainer'
import ScrollingText from '@/components/partials/ScrollingText'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "StatusLayout",

  components: {
    PageFooter,
    MenuBox,
    NavContainer,
    ScrollingText
  },

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      helpText: 'helpText/getHelpText',
      activeViewports: 'viewport/getActiveViewports'
    })
  },

  methods: {
    /**
     * The actions mapped from Vuex.
     */
    ...mapActions({
      openMenu: 'menu/openMenu'
    })
  }
}
</script>

<style lang="scss" scoped>
.status-layout {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: auto;
  padding: .5rem;
}

.app-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 1px; // This is needed to prevent the text from expanding past the width limit, or wrapping.
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: .5rem;
}

.header--row {
  flex-direction: row;
}

.header--bottom {
  margin: 0 1rem .5rem 1rem;
}

.header--bottom.super-small-screen-header-fix {
  margin-left: 0;
  margin-right: 0;
}

.header__empty-box {
  display: flex;
  align-items: flex-end;
  flex-grow: 10;
  margin-right: .5rem;
}

.empty-box {
  height: 80%;
  width: 100%;
  flex-grow: 1;
}

.header__item--help {
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
}

.header__item--menu {
  min-width: 10rem;
  flex-grow: 1;
  cursor: pointer;
}

.content {
  position: relative;
  padding: 0 1rem;
  overflow-y: hidden;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .15s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>