export default {
  /**
   * Sets the value of viewports on the store.
   * 
   * @param {Object} context
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setHelpText({commit}, payload) {
    commit('setHelpText', payload)
  }
}