export default {
  /**
   * Sets the value of viewports on the store.
   * 
   * @param {Object} context
   * @param {Array} payload
   * 
   * @returns {Void}
   */
  setViewports({commit}, payload) {
    commit('setViewports', payload)
  },

  /**
   * Sets the value of activeViewport on the store.
   * 
   * @param {Object} context
   * @param {Array} payload
   * 
   * @returns {Void}
   */
  setActiveViewports({commit}, payload) {
    commit('setActiveViewports', payload)
  }
}