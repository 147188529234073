export default {
  /**
   * Gets the value of contributions on the store.
   * 
   * @param {Object} state
   * 
   * @returns {Number}
   */
  getContributions(state) {
    return state.contributions
  }
}