export default {
  /**
   * Sets the value of contributions on the store.
   * 
   * @param {Object} state
   * @param {Number} payload
   * 
   * @returns {Void}
   */
  setContributions(state, payload) {
    state.contributions = payload
  }
}