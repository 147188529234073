<template>
  <div
    class="footer"
    @mouseenter="showInfo = true"
    @mouseleave="showInfo = false">
    <menu-box>
      <transition name="fade" mode="out-in">
        <template v-if="! showInfo">
          <div class="footer__content footer__content--left-aligned" key="location">
            <span class="footer__location">Tacoma - WA</span>
          </div>
        </template>

        <template v-else>
          <div
            class="footer__content" 
            :class="{
              'footer__content--horizontal': activeViewports.some(({name}) => name === 'footer')
            }"
            key="info">
            <a class="footer__github-link" href="https://github.com/DefrostedTuna" target="_blank">Github</a>
            <span class="footer__crafted">Crafted with &#x2764; by Rick Bennett</span>
            <a class="footer__resume-link" href="https://drive.google.com/file/d/1NUK-sUO76uppwJbyT4Jok86c_hzBUN0u/view" target="_blank">Resume</a>
          </div>
        </template>
      </transition>
    </menu-box>
  </div>
</template>

<script>
import MenuBox from '@/components/partials/MenuBox'
import { mapGetters } from 'vuex';

export default {
  name: "PageFooter",

  components: {
    MenuBox
  },

  data() {
    return {
      showInfo: true
    }
  },

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      activeViewports: 'viewport/getActiveViewports'
    }),
  },

  mounted() {
    setTimeout(() => {
      this.showInfo = false
    }, 5000);
  }
}
</script>

<style lang="scss" scoped>
.footer__content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;

  a {
    color: inherit;
  }

  * {
    margin: 0 0 1rem 0;

    &:last-child {
      margin: 0;
    }
  }
}

.footer__content--horizontal {
  flex-direction: row;

  * {
    margin: 0 1rem 0 0;

    &:last-child {
      margin: 0;
    }
  }
}

.footer__content--left-aligned {
  justify-content: flex-start;
  align-items: flex-start;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>