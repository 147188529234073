import Vue from 'vue'
import VueRouter from 'vue-router'
import PartyPanel from '../components/PartyPanel.vue'
import StatusPanel from '../components/StatusPanel.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'party',
    component: PartyPanel,
    meta: {
      title: 'Party Members'
    }
  },
  {
    path: '/status',
    name: 'status',
    component: StatusPanel,
    meta: {
      title: 'Member Status'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to) => {
  document.title = 'Rick Bennett | ' + to.meta.title;
  store.dispatch('helpText/setHelpText', '')
  store.dispatch('menu/closeMenu')
  store.dispatch('menu/setIsAnimating', false)
});

export default router
