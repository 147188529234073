export default {
  name: 'Rick Bennett',
  imageUrl: 'https://avatars2.githubusercontent.com/u/5687508?v=4',
  birthday: '1988-11-06T00:00:00Z',
  hpMax: 4830,
  weapon: {
    label: 'Apple Keyboard of Endurance',
    helpText: 'The most trusted of keyboards'
  },
  commands: [
    {
      label: 'Code',
      helpText: 'Write a line of code for the target project'
    },
    {
      label: 'Commit',
      helpText: 'Create a commit using the Conventional Commits standard'
    },
    {
      label: 'Git Flow',
      helpText: 'Invoke the Git Flow branching strategy'
    },
    {
      label: 'Deploy',
      helpText: 'Deploy changes to the target environment',
    }
  ],

  /**
   * Stats are calculated using the following;
   * 
   * +52 base
   * +10 for proficiencies
   * +3 for framework
   * +1, 3, 5, or 8 based on rank
   * 
   * Frameworks get boost alongside languages.
   */
  stats: [
    {
      label: 'Html',
      value: 53 // +52, +1(rank 4) = 53
    },
    {
      label: 'Php',
      value: 87 // +52, +10(proficiency), +40%(ability) = 86.8
    },
    {
      label: 'Css',
      value: 55 // +52, +3(rank 3) = 55
    },
    {
      label: 'Laravel',
      value: 91 // +51, +10(proficiency), +3(framework), +40%(ability) = 91
    },
    {
      label: 'Js',
      value: 74 // +52, +10(proficiency), +20%(ability) = 74.4
    },
    {
      label: 'Bash',
      value: 60 // +52, +8(rank 1) = 60
    },
    {
      label: 'Vue',
      value: 78 // +52, +10(proficiency), +3(framework), +20% (ability) = 78
    },
    {
      label: 'K8s',
      value: 57 // +52, +5(rank 2) = 57
    },
  ],
  abilities: [
    {
      label: 'Php+40%',
      helpText: 'Raises Php by 40%'
    },
    {
      label: 'Javascript+20%',
      helpText: 'Raises Javascript by 20%'
    },
    {
      label: 'Auto-CI',
      helpText: 'Continuous Integration activates automatically'
    },
    {
      label: 'Kubernetes Bonus',
      helpText: '+2 Bonus to Kubernetes at level up'
    }
  ],
  bio: `A seasoned software engineer that began his journey in 2013, Rick has actively focused on the open source web stack since the very beginning. 
        Over the years, his skills in both PHP and Javascript have been honed to conquer even the most challenging of trials. 
        Of course, these skills alone haven't been the sole contributing factor to his success. 
        He's also earned a reputation for being proficient with both Laravel and Vue, as well as Docker and Kubernetes in more recent years.
        His documentation skills are exceptional and he's even been known to have learned the obscure language referred to as ZPL.
        This, combined with his unquenchable thirst for knowledge has led him to accept a wide variety of quests. 
        From microservice ecosystems to fully autonomous CI/CD pipelines, Rick is always eager to tackle anything that's thrown his way.`
}