export default {
  /**
   * Gets the value of viewports on the store.
   * 
   * @param {Object} state
   * 
   * @returns {Array}
   */
  getViewports(state) {
    return state.viewports
  },

  /**
   * Gets the value of activeViewport on the store.
   * 
   * @param {Object} state
   * 
   * @returns {Array}
   */
  getActiveViewports(state) {
    return state.activeViewports
  }
}