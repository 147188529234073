<template>
  <div 
  id="app" 
  :class="{ 
    'app--menu-open': (! activeViewports.some(({name}) => name == 'desktop') || this.menu.hideOnDesktop) && this.menu.isOpen
  }">
    <div class="nav-mask" @click="closeMenu()"></div>
    <transition name="main-fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "App",

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      viewports: 'viewport/getViewports',
      activeViewports: 'viewport/getActiveViewports',
    }),

    /**
     * The menu object extracted from Vuex.
     * 
     * Vuex's `mapGetters` doesn't support being nested into an object, 
     * so the next best way to handle this seems to be to map them individually
     * inside of an object. This will allow the properties to be within object context.
     * 
     * @returns {Object}
     */
    menu() {
      return {
        hideOnDesktop: this.$store.getters['menu/getHideOnDesktop'],
        isOpen: this.$store.getters['menu/isOpen'],
      }
    }
  },

  methods: {
    /**
     * The actions mapped from Vuex
     */
    ...mapActions({
      closeMenu: 'menu/closeMenu',
      setIsDesktopViewport: 'menu/setIsDesktopViewport',
      setActiveViewports: 'viewport/setActiveViewports'
    }),

    /**
     * Window resize handler that monitors the viewport's media queries.
     * This handler will update the state of the viewport for use by the menu system.
     * 
     * @returns {Void}
     */
    handleResize() {
      let rootFontSize = parseInt(getComputedStyle(document.documentElement).fontSize)
      let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      let viewports = this.viewports.sort((a, b) => {
        return a.breakpoint > b.breakpoint ? 1 : -1
      })
      let triggered = viewports.filter(item => item.breakpoint <= windowWidth / rootFontSize) // `windowWidth / rootFontSize` will give us the current rem value.
      
      if (this.activeViewports.length !== triggered.length) {
        this.setActiveViewports(triggered)
      }
    }
  },

  mounted() {
    this.handleResize() // Fire once to set the proper values.
    window.addEventListener('resize', this.handleResize)
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style lang="scss">

* {
  box-sizing: border-box;
}

html {
  font-size: global-variables.$application-root-font-size;

  @media screen and (min-width: 75rem) {
    font-size: 1.25rem;
  }

  @media screen and (min-width: 125rem) {
    font-size: 1.5rem;
  }
}

body {
  margin: 0;
  padding: 0;
  background: black;
  font-size: 1.75rem;
  line-height: 1.25;
}

#app {
  margin: auto;
  min-height: 100vh;
  max-width: global-variables.$max-application-width;
  overflow-x: hidden;
  
  font-family: sans-serif;
  font-family: Arial;
  letter-spacing: .025rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// This will prevent the page from scrolling when the menu is open.
.app--menu-open {
  height: 100vh;
  overflow-y: hidden;

  .nav-mask {
    visibility: visible;
    opacity: 1;
  }
}

.nav-mask {
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  transition: visibility .5s ease, opacity .5s ease;
}

.main-fade-enter-active, .main-fade-leave-active {
  transition: opacity .75s;
}
.main-fade-enter, .main-fade-leave-to {
  opacity: 0;
}
</style>
