var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-container",class:{ 
    'nav-container--open': _vm.menu.isOpen, 
    'nav-container--desktop': _vm.activeViewports.some(({name}) => name === 'desktop') && ! _vm.hideOnDesktop,
    'nav-container--animating': _vm.menu.isAnimating
  },attrs:{"id":"nav-container"},on:{"transitionend":function($event){return _vm.setIsAnimating(false)}}},[_c('div',{staticClass:"nav",class:{
      'nav--desktop': _vm.activeViewports.some(({name}) => name === 'desktop') && ! _vm.hideOnDesktop
    }},[_c('main-menu')],1),_c('div',{staticClass:"misc-info-container"},[_c('misc-info')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }