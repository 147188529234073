export default {
  /**
   * Sets the value of isAnimating on the store.
   * 
   * @param {Object}  state
   * @param {Boolean} payload
   * 
   * @returns {Void}
   */
  setIsAnimating(state, payload) {
    state.isAnimating = payload
  },

  /**
   * Sets the value of isOpen on the store.
   * 
   * @param {Object}  state
   * @param {Boolean} payload
   * 
   * @returns {Void}
   */
  setIsOpen(state, payload) {
    state.isOpen = payload
  },

  /**
   * Sets the value of desktopBreakpoint on the store.
   * 
   * @param {Object}  state
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setDesktopBreakpoint(state, payload) {
    state.desktopBreakpoint = payload
  },

  /**
   * Sets the value of hideOnDesktop on the store.
   * 
   * @param {Object}  state
   * @param {Boolean} payload
   * 
   * @returns {Void}
   */
  setHideOnDesktop(state, payload) {
    state.hideOnDesktop = payload
  },

  /**
   * Sets the value of isDesktopViewport on the store.
   * 
   * @param {Object}  state
   * @param {Boolean} payload
   * 
   * @returns {Void}
   */
  setIsDesktopViewport(state, payload) {
    state.isDesktopViewport = payload
  }
}