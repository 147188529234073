export default {
  /**
   * The viewports and their breakpoints in REM.
   * 
   * @param {Array}
   */
  viewports: [
    { 
      name: 'super-mobile',
      breakpoint: 1 // >= 16px @16px root
    },
    { 
      name: 'mobile',
      breakpoint: 26.25 // >= 420px @16px root
    },
    {
      name: 'reserve-members',
      breakpoint: 35.625 // 550px @16px root
    },
    {
      name: 'desktop',
      breakpoint: 50 // >= 800px @16px root
    },
    {
      name: 'footer',
      breakpoint: 60 // >= 960px @16px root
    }
  ],

  /**
   * The viewports that are currently active.
   * 
   * @param {Array}
   */
  activeViewports: []
}