<template>
  <status-layout>
    <div 
      class="status-panel"
      :class="{
        'status-panel--desktop': activeViewports.some(({name}) => name === 'desktop')
      }">
      <!-- Panel - Character Details -->
      <div class="character-details">
        <!-- Portrait -->
        <div class="character-details__portrait">
          <img class="portrait" :src="character.imageUrl" alt="portrait">
        </div>

        <!-- Basic Info -->
        <div class="character-details__basic-info" :class="{ 'character-details__basic-info--critical': isCritical }">
          <!-- Name -->
          <span class="basic-info__name">{{ character.name }}</span>

          <!-- Level -->
          <div class="basic-info__level">
            <span class="level__label">LV</span>
            <span class="level__value numerical-spacing">{{ characterLevel }}</span>
            <span class="level__star">
              <img src="~@/assets/img/yellow-star.svg" alt="">
              <div class="star__leader-line">
                <div class="leader-line">
                  <span class="leader-line--fill"></span>
                </div>
                <div class="leader-line">
                  <span class="leader-line--fill"></span>
                </div>
              </div>
            </span>
          </div>

          <!-- HP -->
          <div class="basic-info__hp">
            <span class="hp__label">HP</span>
            <div class="hp__values">
              <span class="hp__current numerical-spacing">{{ currentHP }}/</span>
              <span class="hp__max numerical-spacing">{{ character.hpMax }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Panel - Character Stats -->
      <div class="character-stats" :class="{ 'super-small-screen-character-stats-fix': ! activeViewports.some(({name}) => name === 'mobile') }">
        <div 
          v-for="(stat, index) in character.stats" 
          :key="index" 
          class="character-stat"
          :class="`character-stat--${stat.label}`">
          <span class="stat__label">{{ stat.label }}</span>
          <span class="stat__value">{{ stat.value }}</span>
        </div>
      </div>

      <!-- Panel Character EXP -->
      <div class="character-exp">
        <div class="current-exp" :class="{ 'super-small-screen-current-exp-fix': ! activeViewports.some(({name}) => name === 'mobile') }">
          <span class="current-exp__label">Current EXP</span>
          <span class="current-exp__value numerical-spacing">{{ currentExp }}p</span>
        </div>
        <div class="needed-exp" :class="{ 'super-small-screen-needed-exp-fix': ! activeViewports.some(({name}) => name === 'mobile') }">
          <span class="needed-exp__label">Next LEVEL</span>
          <span class="needed-exp__value numerical-spacing">{{ nextLevelExp }}p</span>
        </div>
      </div>

      <!-- Panel - Character Commands -->
      <div class="character-commands">
        <menu-box title="Command">
          <span 
            v-for="(command, index) in character.commands" 
            :key="index" 
            class="command"
            @mouseenter="setHelpText(command.helpText)"
            @mouseleave="resetHelpText()">
            {{ command.label }}
          </span>
        </menu-box>
      </div>

      <!-- Panel - Character Weapon -->
      <div class="character-weapon">
        <menu-box
          @mouseenter.native="setHelpText(character.weapon.helpText)"
          @mouseleave.native="resetHelpText()">
          <span>
            {{ character.weapon.label }}
          </span>
        </menu-box>
      </div>

      <!-- Panel - Character Abilities -->
      <div class="character-abilities">
        <menu-box title="Ability">
          <div 
            v-for="(ability, index) in character.abilities" 
            :key="index" 
            class="ability"
            @mouseenter="setHelpText(ability.helpText)"
            @mouseleave="resetHelpText()">
            <div class="ability__icon">
              <img src="~@/assets/img/character-ability.svg" alt="ability-icon">
            </div>
            <span class="ability__name">{{ ability.label }}</span>
          </div>
        </menu-box>
      </div>

      <!-- Panel - Character Bio -->
      <div class="character-bio-trigger" @click="openCharacterBio()">
        <!-- Placeholder Button-->
        <menu-box
          @mouseenter.native="setHelpText('View additional character information')"
          @mouseleave.native="resetHelpText()">
          <span>More Information</span>
        </menu-box>
      </div>

      <!-- Character Bio -->
      <div class="character-bio"
        :class="{
          'character-bio--desktop': activeViewports.some(({name}) => name === 'desktop'),
          'character-bio--open': bioMenu
        }" @click="closeCharacterBio()">
        <menu-box title="Bio" class="character-bio__menu-box">
          <div class="character-bio__content">{{ character.bio }}</div>
        </menu-box>
      </div>

    </div>
  </status-layout>
</template>

<script>
import MenuBox from '@/components/partials/MenuBox'
import StatusLayout from '@/views/layouts/StatusLayout'
import character from '@/data/character'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "StatusPanel",

  components: {
    MenuBox,
    StatusLayout
  },

  data() {
    return {
      character: character,
      currentExp: 0,
      nextLevelExp: 0,
      bioMenu: false,
    }
  },

  computed: {
    /**
     * The Getters mapped from Vuex.
     */
    ...mapGetters({
      activeViewports: 'viewport/getActiveViewports'
    }),

    /**
     * Determines if the character is in "critical" HP (25% or below).
     * 
     * @returns {Boolean}
     */
    isCritical() {
      return ((this.currentHP / this.character.hpMax) * 100) <= 25
    },

    /**
     * Randomly generates the character's current HP based on their configured maximum.
     * 
     * @returns {Number}
     */
    currentHP() {
      return Math.floor(Math.random() * this.character.hpMax);
    },

    /**
     * The character's level, based on the configured birthday.
     * 
     * @returns {String}
     */
    characterLevel() {
      let birthday = new Date(this.character.birthday)
      let now = new Date()
      let years = Math.floor(Math.abs(now - birthday) / 3.154e+10)

      return years
    }
  },

  methods: {
    /**
     * The Actions mapped from Vuex.
     */
    ...mapActions({
      setHelpText: 'helpText/setHelpText'
    }),

    /**
     * Resets the help text back to an empty string.
     * 
     * @returns {Void}
     */
    resetHelpText() {
      this.setHelpText('')
    },

    /**
     * Sets the current EXP obtained.
     * 
     * This is based on the number of minutes that have elapsed since the last birthday.
     * 
     * @returns {Void}
     */
    setCurrentExp() {
      let birthday = new Date(this.character.birthday)
      let lastBirthday = birthday.setFullYear(birthday.getFullYear() + this.characterLevel)
      let now = new Date()

      let diff = now - lastBirthday

      this.currentExp = Math.floor((diff / 1000) / 60)
    },

    /**
     * Sets the EXP needed to obtain the next level.
     * 
     * This is based on the number of minutes until the next birthday.
     * 
     * @returns {Void}
     */
    setNextLevelExp() {
      this.nextLevelExp = Math.floor(525600 - this.currentExp)
    },

    /**
     * Opens the character's bio menu.
     * 
     * @returns {Void}
     */
    openCharacterBio() {
      this.bioMenu = true
    },

    /**
     * Closes the character's bio menu.
     * 
     * @returns {Void}
     */
    closeCharacterBio() {
      this.bioMenu = false
    }
  },

  mounted() {
    this.setCurrentExp()
    this.setNextLevelExp()

    setInterval(() => {
      this.setCurrentExp()
      this.setNextLevelExp()
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
.status-panel {
  position: relative;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 0 0 .5rem 0;

  & > div {
    flex-grow: 1;
    margin-bottom: .5rem;
  }

  & > .character-bio-trigger,
  & > .character-bio {
    margin-bottom: 0;
  }
}

.status-panel--desktop {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  & > .character-details {
    order: 1;
    flex-shrink: 0;
    padding-right: 1rem;
  }

  & > .character-exp {
    width: 50%;
    order: 3;
    padding-right: 1rem;
    margin-bottom: 1.5rem;
  }

  & > .character-stats {
    width: 50%;
    order: 5;
    padding-right: 1.5rem;
  }

  & > .character-commands {
    order: 2;
    padding-left: 1rem;
    max-width: 15rem;
  }

  & > .character-weapon {
    width: 50%;
    order: 4;
    padding-left: 1rem;
    margin-bottom: 1.5rem;
  }

  & > .character-abilities {
    width: 50%;
    order: 6;
    padding-left: 1rem;
  }

  & > .character-bio-trigger {
    order: 7;
  }
}

// Character Details
//==================

.character-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 1.5rem;
}

.character-details__portrait {
  height: 9rem;
  width: 6rem;
  flex-shrink: 0;
  margin-right: 1rem;
  margin-bottom: .5rem !important;
  overflow: hidden;
}

.portrait {
  height: 100%;
  transform: translateX(-20%);
}

.character-details__basic-info {
  display: flex;
  flex-direction: column;
}

.character-details__basic-info--critical {
  color: #f2ff00;
}

.basic-info__name,
.basic-info__level {
  margin-bottom: 1rem;
}

.basic-info__level {
  display: flex;
  flex-direction: row;
  width: 70%;
}

.level__value {
  font-weight: 700;
  margin-left: auto; // Force the numbers to the right side of the container.
  margin-right: .5rem;
}

.level__star {
  position: relative;
  width: .9rem;

  img {
    position: absolute;
    top: 0;
    width: .9rem;
  }
}

.star__leader-line {
  display: flex;
  flex-direction: column;
  height: 50%;
  transform: translateY(100%);
}

.leader-line {
  height: .1875rem;
  width: 75%;
  margin: 0 auto .125rem;
  background: yellow;
  border-top: .0625rem solid rgba(0, 0, 0, 0.6);
  content: "";
}

.basic-info__hp {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.hp__label {
  margin-right: 3rem;
}

.hp__current {
  min-width: 4rem;
  margin-right: 1rem;
  font-weight: 700;
}

.hp__max {
  min-width: 4rem;
  font-weight: 700;
}

// Character EXP
//==============

.character-exp {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.current-exp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.super-small-screen-current-exp-fix {
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: .5rem;

  .current-exp__label {
    width: 100%;
    border-bottom: .0625rem solid white;
  }
}

.current-exp__value {
  font-family: "Arial Black";
  font-weight: 900;
}

.needed-exp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.super-small-screen-needed-exp-fix {
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: .5rem;

  .needed-exp__label {
    width: 100%;
    border-bottom: .0625rem solid white;
  }
}

.needed-exp__value {
  font-family: "Arial Black";
  font-weight: 600;
}

// Character Stats
//================

.character-stats {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  padding: 1rem .5rem;
}

.super-small-screen-character-stats-fix {
  .character-stat {
    width: 100%;
  }
}

.character-stats.super-small-screen-character-stats-fix {
  .character-stat:nth-child(1) {
    order: 1;
  }
  .character-stat:nth-child(2) {
    order: 5;
  }
  .character-stat:nth-child(3) {
    order: 2;
  }
  .character-stat:nth-child(4) {
    order: 6;
  }
  .character-stat:nth-child(5) {
    order: 3
  }
  .character-stat:nth-child(6) {
    order: 7;
  }
  .character-stat:nth-child(7) {
    order: 4;
  }
  .character-stat:nth-child(8) {
    order: 8;
  }
}

.character-stat {
  display: flex;
  width: 50%;
  padding: 0 1rem;
}

.stat__label {
  margin-right: auto;
}

.stat__value {
  font-weight: 700;
}


// Character Commands
//===================

.command {
  display: block;
  margin-bottom: .25rem;
}

// Character Weapon
//=================

.character-weapon {
  width: 100%;
}

// Character Abilities
//====================
.ability {
  display: flex;
  margin-bottom: .25rem;
}

.ability__icon {
  width: 1.75rem;
  margin-right: .25rem;
  flex-shrink: 0;

  img{
    width: 100%;
    filter: drop-shadow(0px 0px 3px #5151bd);
  }
}

// Character Bio
//==============

.character-bio-trigger {
  width: 100%;

  &:hover {
    cursor: pointer;
  }
}

.character-bio { 
  z-index: 3;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateY(calc(100% + .5rem));
  visibility: hidden;
  opacity: 0;
  transition: transform .5s ease, visibility .5s ease, opacity .5s ease, bottom .5s ease;

  &:hover {
    cursor: pointer;
  }
}

.character-bio--desktop {
  position: absolute;
  top: initial;
  right: -1rem;
  bottom: .5rem;
  left: -1rem;
  height: 78%;
  min-width: 100%;
  transform: translateY(100%);
}

.character-bio--open {
  z-index: 3;
  transform: translateY(0%);
  visibility: visible;
  opacity: 1;
}

.character-bio__menu-box {
  height: 100%;
  width: 100%;
}

.character-bio__content {
  font-size: 1.5rem;
}
</style>