var render = function render(){var _vm=this,_c=_vm._self._c;return _c('menu-box',[_c('div',{staticClass:"main-menu"},[_c('div',{staticClass:"menu-items"},[_vm._l((_vm.menuItems),function(item,index){return [(item.routeName)?_c('router-link',{key:index,staticClass:"menu-item",class:{
            'menu-item--disabled': item.disabled
          },attrs:{"to":{ name: item.routeName }},nativeOn:{"mouseenter":function($event){return _vm.setHelpText(item.helpText)},"mouseleave":function($event){return _vm.resetHelpText()}}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(item.link)?_c('a',{key:index,staticClass:"menu-item",class:{
            'menu-item--disabled': item.disabled
          },attrs:{"href":item.link,"target":"_blank"},on:{"mouseenter":function($event){return _vm.setHelpText(item.helpText)},"mouseleave":function($event){return _vm.resetHelpText()}}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(! item.routeName && ! item.link)?_c('span',{key:index,staticClass:"menu-item",class:{
            'menu-item--disabled': item.disabled
          },on:{"mouseenter":function($event){return _vm.setHelpText(item.helpText)},"mouseleave":function($event){return _vm.resetHelpText()}}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()]}),_c('span',{staticClass:"menu-item",class:{
          'hide': _vm.activeViewports.some(({name}) => name === 'desktop')
        },on:{"click":function($event){return _vm.closeMenu()},"mouseenter":function($event){return _vm.setHelpText('Close Main Menu')},"mouseleave":function($event){return _vm.resetHelpText()}}},[_vm._v(" Exit ")])],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }